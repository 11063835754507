<template>
  <div v-if="hasResource('customer_management')" class="aops-user-list">
    <change-status
      ref="changeStatusPop"
      @update="getCustomerList"
    ></change-status>
    <add-points ref="addPointsPop" @update="getCustomerList"></add-points>
    <cancel-account
      ref="cancelAccountPop"
      @update="getCustomerList"
    ></cancel-account>
    <add-new ref="addNewPop" @update="getCustomerList"></add-new>
    <div class="search-form">
      <el-button
        type="primary"
        @click="addNewCustomer"
        class="black-btn"
        v-if="hasResource('customer_add')"
        >新增用户</el-button
      >
      <div class="dis-fix">
        <el-form :inline="true">
          <el-form-item label="用户名称">
            <el-input
              v-model="username"
              placeholder="输入用户名称"
              clearable
              @keyup.enter.native="addByEnterKey"
            />
          </el-form-item>
          <el-form-item label="注册邮箱">
            <el-input
              v-model="email"
              placeholder="输入邮箱"
              clearable
              @keyup.enter.native="addByEnterKey"
            />
          </el-form-item>
          <el-form-item label="用户状态">
            <el-select v-model="status">
              <el-option
                v-for="item in options.status"
                :key="item.name"
                :label="item.name"
                :value="item.value"
                @keyup.enter.native="addByEnterKey"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="text-btn">
          <span class="serach-button" @click="handleQuery">查询</span>
          <span class="clear-button" @click="resetQuery">清空</span>
        </div>
      </div>
    </div>
    <el-table
      ref="table"
      :data="customerData"
      class="data-table aop-table"
      v-loading="isTableLoading"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      :row-class-name="tableRowClassName"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      @sort-change="sortChange"
    >
    <el-table-column
        v-for="col in tableColumns"
        :key="col.label"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :fixed="col.fixed">
        <template slot-scope="{row}">
          <div v-if="col.prop === 'status'">
            {{getStatus(row.status)}}
          </div>
          <div v-else-if="col.prop === 'subscription_type'">
            {{getSubscriptionType(row.subscription_type)|| '--'}}
          </div>
          <div v-else-if="col.prop === 'subscribe'">
           {{row[col.prop] ? "是" :"否"}}
          </div>
          <div v-else-if="col.prop === 'operation'" class="icon-btn">
            <el-button
                size="mini"
                type="text"
                @click="goToCustomerDetail(row)"
                v-if="hasResource('customer_info')"
                >
                <i class="iconfont">&#xe662;</i>查看
            </el-button>
            <el-button
                size="mini"
                type="text"
                @click="changeCustomerStatus(row)"
                v-if="hasResource('customer_change_status')"
                >
                <i class="iconfont">&#xe60f;</i>状态变更
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="addPoints(row)"
             v-if="hasResource('customer_balance_adjustment')"
            >
              <i class="iconfont">&#xe60a;</i>积分变更
            </el-button>
          </div>
          <div v-else>{{row[col.prop] || '--'}}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurPageChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        v-if="total != 0"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../../../api/customer";
import customer from "../../../mixins/customer";
import vars from "./vars";
import {
  changeStatus,
  addPoints,
  cancelAccount,
  addNewCustomer,
} from "./dialogs/index.js";

export default {
  components: {
    ChangeStatus: changeStatus,
    AddPoints: addPoints,
    CancelAccount: cancelAccount,
    AddNew: addNewCustomer,
  },
  mixins: [customer],
  watch: {
    customerData: function () {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },
  },
  data() {
    return {
     tableColumns: [
        { prop: 'username', label: '用户名称', width: 200, align: 'left', },
        { prop: 'email', label: '注册邮箱', width: 240, align: 'left' },
        { prop: 'company', label: '公司', width: 140, align: 'left' },
        { prop: 'created_at', label: '创建日期', width: 150, align: 'left' },
        { prop: 'balance', label: '积分', width: 130, align: 'left' },
        { prop: 'status', label: '用户状态', width: 140, align: 'left' },
        { prop: 'subscribe', label: '关注状态', width: 140, align: 'left' },
        { prop: 'register_market', label: '注册地区', width: 100, align: 'left' },
        { prop: 'operation', label: '操作', width: 380, align: 'left', fixed: 'right' }
     ],
      header: {
        url: "list",
      },
      customerData: [],
      order_by: "created_at",
      sort: "DESC",
      currentPage: 1,
      pagesize: 10,
      total: 0,
      channelList: [],
      username: "",
      status: "",
      email: "",
      company: "",
      isTableLoading: false,
      locationList: vars.locationList,
      options: {
        status: [
          { name: "全部", value: "" },
          { name: "邮箱未认证", value: 2 },
          { name: "正常", value: 1 },
          { name: "停用", value: 0 },
          { name: "锁定", value: 3 },
        ],
      },
      parameters: false,
      recordDate: {},
      news: false,
    };
  },

  computed: {
    query() {
      const params = {
        limit: this.pagesize,
        page: this.currentPage,
        filter: {
          username: this.username,
          email: this.email,
          company: this.company,
          status: this.status,
        },
      };
      return params;
    },
    recordQuery() {
      const params = {
        limit: this.recordDate.limit,
        page: this.recordDate.page,
        filter: {
          username: this.recordDate.username,
          email: this.recordDate.email,
          status: this.recordDate.status,
        },
      };
      return params;
    },
  },

  created() {
    this.msgBus.$on("userList", () => {
      this.recordCurrentPage("needUpdate", true);
    });
    this.init();
  },

  methods: {
    async init() {
      await api.channelList().then((res) => {
        if (res.data.code === 10200) {
          this.channelList = res.data.data;
        }
        if (res.data.code === 10100) {
          this.$router.push({ path: "/" });
        }
      });
      if (this.getLocalCurrentPage("needUpdate")) {
        await this.updateFilterParams();
        this.recordCurrentPage("needUpdate", false);
      } else {
        await this.getCustomerList();
      }
    },
    addByEnterKey(e) {
      if (e.keyCode == 13) {
        this.handleQuery();
      }
    },

    updateFilterParams() {
      this.recordDate = this.getLocalCurrentPage("userList0415");
      let params = this.recordDate;
      if (params) {
        this.parameters = true;
        this.pagesize = params ? params.limit : this.pagesize;
        this.currentPage = params ? params.page : this.currentPage;
        this.username = params ? params.username : this.username;
        this.email = params ? params.email : this.email;
        this.status = params ? params.status : this.status;
        this.getCustomerList();
      }
    },

    getCustomerList() {
      this.isTableLoading = true;
      let params = this.parameters ? this.recordQuery : this.query;
      params.sort = this.sort;
      params.order_by = this.order_by;
      api.customerList(params).then((res) => {
        if (res.data.code === 10200) {
          this.customerData = res.data.data.items;
          this.total = res.data.data.total;
          if (this.customerData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getCustomerList();
          }
        }
        this.isTableLoading = false;
      });
    },

    // 搜索
    handleQuery() {
      this.parameters = false;
      this.currentPage = 1;
      this.getCustomerList();
    },

    // 重置
    resetQuery() {
      this.username = "";
      this.email = "";
      this.status = "";
    },

    handleSizeChange(val) {
      this.parameters = false;
      this.pagesize = val;
      this.getCustomerList();
    },

    handleCurPageChange(val) {
      this.parameters = false;
      this.currentPage = val;
      this.getCustomerList();
    },

    tableRowClassName({ row, rowIndex }) {
      if (row.status === 0) {
        return "stop-row";
      }
      return "";
    },

    goToCustomerDetail(row) {
      this.$router.push({
        path: "/customer",
        query: {
          id: row.id,
        },
      });
      const params = {
        limit: this.pagesize,
        page: this.currentPage,
        username: this.username,
        email: this.email,
        // company: this.company,
        status: this.status,
      };
      this.recordCurrentPage("userList0415", params);
    },

    changeCustomerStatus(row) {
      this.$nextTick(() => {
        this.$refs.changeStatusPop.show(row);
      });
    },

    addPoints(row) {
      this.$nextTick(() => {
        this.$refs.addPointsPop.show(row);
      });
    },

    cancelAccount(id) {
      this.$nextTick(() => {
        this.$refs.cancelAccountPop.show(id);
      });
    },

    //新增用户
    addNewCustomer() {
      this.$nextTick(() => {
        this.$refs.addNewPop.show();
      });
    },
    sortChange(column, prop, order) {
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.getCustomerList();
    },
    getStatus(status) {
        switch (status) {
        case 0:
            return "停用";
        case 1:
            return "正常";
        case 2:
            return "邮箱未认证";
        case 3:
            return "锁定";
        }
    },
    getSubscriptionType(status) {
      switch (status) {
        case 0:
            return "未订阅";
        case 1:
            return "个人版";
        case 2:
            return "专业版";
      }
    }
  },
};
</script>
<style lang="less">
@import "scss/customerManagement.less";
</style>
